<template>
    <div class="apply-promoter">
        <div class="apply-promoter-into-box">
            <van-form style="width: 100%" @submit="submitPromoter" ref="formPromoter">
                <van-field
                    v-model="info.phone"
                    placeholder="请输入手机号"
                    name="phone"
                    label="手机号"
                    :rules="[{ pattern, message: '请检查手机号' }]"
                />
                <van-field
                    v-model="info.code"
                    placeholder="请输入验证码"
                    label="验证码"
                    name="code"
                    :rules="[{ required: true, message: '请检查验证码' }]"
                >
                    <template #button>
                        <van-button
                            size="small"
                            round
                            color="#FA607D"
                            type="primary"
                            native-type="submit"
                            @click="sendCode"
                        >{{ phoneTip }}</van-button>
                    </template>
                </van-field>
                <van-field
                    v-model="info.password"
                    placeholder="请输入新密码"
                    label="新密码"
                    name="password"
                    :rules="[{ required: true, message: '请检查新密码' }]"
                />
                <van-field
                    v-model="info.confirmPassword"
                    placeholder="请确认新密码"
                    label="确认新密码"
                    name="confirmPassword"
                    :rules="[{ required: true, message: '请检查确认密码' }]"
                />
                <div class="submit-box">
                    <van-button type="primary" color="#FB748F" native-type="submit">确定</van-button>
                </div>
            </van-form>
        </div>
    </div>
</template>

<script>
import { Button, Toast, Form, Field } from "vant";
import checkRules from "@/utils/checkRules";
import { updateTeacherPassword, sendSmsCode } from "@/api/home";
let countdown = 60;
export default {
    components: {
        [Button.name]: Button,
        [Form.name]: Form,
        [Field.name]: Field,
    },
    data() {
        return {
            result: ["b"],
            active: 0,
            pattern: /\d{11}/,
            phoneTip: "获取验证码",
            info: {
                phone: "",
                code: "",
                password: "",
                confirmPassword: "",
            }
        };
    },
    created() {
        document.title = "修改密码";
        this.$store.commit("updateBarText", "修改密码");
    },
    methods: {
        setTime() {
            let siv = setInterval(() => {
                if (countdown == 0) {
                    countdown = 60;
                    this.phoneTip = "获取验证码";
                    clearInterval(siv);
                } else {
                    this.phoneTip = countdown + "s后重新发送";
                    countdown--;
                }
            }, 1000);
        },
        sendCode() {
            var that = this;
            this.$refs.formPromoter.validate(["phone"]).then(() => {
                if (countdown == 60) {
                    sendSmsCode({ phone: that.info.phone }).then((res) => {
                        if (res.data.code == 200) {
                            that.setTime();
                        }
                    });
                } else {
                    return;
                }
            })
        },
        submitPromoter() {
            var that = this;
            this.$refs.formPromoter.validate().then(() => {

                if (that.info.password != that.info.confirmPassword) {
                    Toast.fail({
                        message: "两次密码不相同",
                    });
                } else {
                    updateTeacherPassword(that.info).then((res) => {
                        const { data } = res
                        if (data.code == 200) {
                            Toast.success({
                                message: data.msg,
                                onOpened: () => {
                                    that.$router.push("/login");
                                },
                            });
                        }
                    });
                }
            });
        },
    },
};
</script>

<style lang="less" scoped>
.apply-promoter {
    width: 100%;
    height: 100%;
    padding: 0px 15px;
    padding-top: 55px;
    .apply-promoter-into-box {
        width: 100%;
        height: 100%;
    }
    .submit-box {
        width: 100%;
        height: 100px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        .van-button {
            width: 250px;
            height: 55px;
            color: white;
            box-shadow: 2px 2px 5px #fb748f;
            border-radius: 40px;
        }
    }
}
</style>